<template>
  <div class="mt-20">
    <div class="d-flex justify-content-center">
      <div class="col-12">
        <div class="container">
          <nav v-if="hideLoading" class="navbar alert alert-success fixed-top rounded-none" style="margin-top:3.1rem;border-radius: 0 ">
            Scroll down to the last page to sign the agreement.
          </nav>
        </div>
        <div class="container">
          <div  v-if="!hideLoading" class="alert alert-info" role="alert">
            Downloading Partner's Agreement...Please wait.
          </div>
          <pdf
            v-for="i in numPages"
            :key="i"
            :src="src"
            :page="i"
            style="display: inline-block; width: 100%"
          ></pdf>
        </div>

        <hr class="mt-6">

       <div class="row ">
         <div class="container">
           <div class="row">
             <div class="col-sm">
               <a href="https://partners-agreement.s3.eu-west-2.amazonaws.com/unsigned_agreement.pdf" target="_blank" class="btn btn-warning btn-sm mb-3">Download and Execute Later</a>
             </div>
             <div class="col-sm">

             </div>
             <div class="col-sm">

              <p> <input type="checkbox" checked v-model="checkedConditions"> I agree to the terms and conditions</p>
               <button v-if="hideLoading" :disabled="!checkedConditions" type="button" @click="signAgreement" class="btn btn-primary  btn-block mb-8">Execute Now</button>
             </div>
           </div>
         </div>
       </div>
      </div>
    </div>

  </div>
</template>
<script>
import pdf from 'vue-pdf'
var loadingTask = pdf.createLoadingTask('https://partners-agreement.s3.eu-west-2.amazonaws.com/unsigned_agreement.pdf');
export default {
  name: 'Admin',
  data(){
    return{
      checkedConditions: true,
      src: loadingTask,
      numPages: undefined,
      hideLoading: false,
      fields: [
        { key: 'index',label:"SN", sortable: true },
        { key: 'application_status', label: 'Signed', sortable: false,},
        { key: 'name', sortable: true },
        { key: 'phone_no', label: 'Phone No', sortable: true, },
        { key: 'email', label: 'Email', sortable: true, },
        { key: 'download', label: 'Download', sortable: false,},
        { key: 'signed', label: 'Date Signed', sortable: false,},
        { key: 'activate', label: 'Activate', sortable: false,},
      ],
      allUsers: [],
      meta:{
        currentPage: 8,
        totalRows:20,
        perPage:10
      },
      pdfLink: 'https://partners-agreement.s3.eu-west-2.amazonaws.com/unsigned_agreement.pdf'
    }
  },
  components:{
    pdf
  },
  methods:{
    getAllUsers(){
      this.showLoader()
      this.$_getAllUsers().then((response)=>{
        this.allUsers = response.data.body;
        this.meta = response.data.meta;
        this.hideLoader()
      });
    },
   switchPartnerStatus(id,status) {
       let payload={id, status}
        this.$_switchPartnerStatus(payload).then((response)=>{
          this.getAllUsers()
        }).bind(this);
    },
    signAgreement() {
      if (this.checkedConditions) {
        this.showLoader()
        this.$_signAgreement({id:this.$accountUser.id}).then((response=>{
          localStorage.setItem('accountUser', JSON.stringify(response.data.accountUser));
          this.$router.replace({name:'SignComplete'})
          this.hideLoader()
        }).bind(this)).catch((error)=>{
          this.hideLoader()
          console.log(error)
        })
      }else {
        alert("you need to agree to the terms and conditions")
      }
    }
  },
  mounted() {

    this.getAllUsers();
    setTimeout(function () {
      this.hideLoading = true;
    }.bind(this),5000);
    this.src.promise.then(pdf => {

      this.numPages = pdf.numPages;
    });
  }
};
</script>
<style>
 .btn-xs{
   font-size: 11px !important;
 }
</style>
