<template>
  <div class="mt-10">
    <div class="container">

      <div class="row">
        <div class="col d-flex justify-content-center">
          <img src="../assets/cloudenlylargelogo.png" class="h-24">
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <h5><strong>CLOUDENLY PARTNERSHIP APPLICATION </strong></h5>
        <div/>
      </div>
      <h4 class="mt-10">I am applying as an</h4>
      <hr>
      <div>
        <div class="form-row">
          <div class="form-group col-12 col-md-4">
            <select name="" class="form-control" v-model="profile.contact.is_org">
              <option :value="true">Individual</option>
              <option :value="false">Organization</option>
            </select>
          </div>
        </div>
      </div>
      <hr>
      <div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="company">Company Name <span class="text-danger">(for organizations only) </span></label>
            <input id="company" v-model="profile.org.name" type="text" class="form-control" placeholder="">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="address">Address</label>
            <input id="address" v-model="profile.org.address" type="text" class="form-control" placeholder="">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="btype">Core Area of Specialization</label>
            <v-select label="name" :reduce="corespecialization => corespecialization.id"
                      v-model="profile.org.business_nature"
                      :options="corespecializations"></v-select>
          </div>
          <div class="form-group col-md-6">
            <label>Industry Sector</label>
            <v-select label="name" @input="loadSpecializations" :reduce="industry => industry.id"
                      v-model="profile.org.industry" :options="industries"></v-select>
          </div>

        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Industry Sub-sector</label>
            <v-select label="name" :reduce="specialization => specialization.id" v-model="profile.org.specialization"
                      :options="specializations"></v-select>
          </div>
          <div class="form-group col-md-6">
            <label for="ptype">What type of partnership are you applying for?</label>
            <div class="form-group">
             <span class="mr-3">
               <input v-model="varChecked" @change="togglePartnerType('var')" id="var" value="var" type="checkbox">
               <label for="var">Value Added Reseller </label>
             </span>
              <span class="mr-3">
               <input v-model="resellerChecked" @change="togglePartnerType('reseller')" id="reseller" value="reseller"
                      type="checkbox">
               <label for="reseller"> Reseller </label>
             </span>
              <span class="mr-3">
               <input v-model="profile.contact.is_an_affiliate" id="affiliate" type="checkbox">
               <label for="affiliate">Affiliate</label>
             </span>

            </div>
            <div class="-mt-2 ml-4">
              <small class="text-primary">You can select more than one partner type. Note: You cannot be a VAR and
                Reseller at the same time </small>
            </div>
          </div>
        </div>
        <h4 class="mt-10">Contact Person</h4>
        <hr>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="fname">First Name</label>
            <input type="text" v-model="profile.contact.fname" class="form-control" id="fname">
          </div>
          <div class="form-group col-md-6">
            <label for="lname">Last Name</label>
            <input type="text" v-model="profile.contact.lname" class="form-control" id="lname">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="email">Gender</label>
            <select v-model="profile.contact.gender" name="" id="" class="form-control">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label for="phone">Phone No</label>
            <input type="text" v-model="profile.contact.phone_no" class="form-control" id="phone">
          </div>

        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="email">Email</label>
            <input type="email" v-model="profile.contact.email" class="form-control" id="email">
          </div>
        </div>
      </div>

      <p><label for="check"><input v-model="checked" type="checkbox" id="check"> I have read and agreed to the <a
        target="_blank" href="https://scelloo.com/privacy/non-disclosure-agreement/">Non-Disclosure
        Agreement</a></label></p>
      <div class="row justify-content-center mb-24">
        <div class="col-4">
          <button type="submit" class="btn btn-danger w-100" v-if="!submitting" @click="apply">Apply</button>
          <button disabled class="btn btn-danger w-100" v-else>Submitting</button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SignUp',
  components: {},
  data() {
    return {
      submitting: false,
      varChecked: false,
      resellerChecked: false,
      checked: false,
      industries: [],
      specializations: [],
      corespecializations: [
        {
          'id': 1,
          'name': 'Accounting, Auditing and Book-keeping'
        },
        {
          'id': 2,
          'name': 'Benefit Administration'
        },
        {
          'id': 3,
          'name': 'Budget Planning and Management'
        },
        {
          'id': 4,
          'name': 'Business Intelligence and Data Analytics'
        },
        {
          'id': 5,
          'name': 'Channel Management'
        },
        {
          'id': 6,
          'name': 'Customer Relationship Management'
        },
        {
          'id': 7,
          'name': 'Expense Management'
        },
        {
          'id': 9,
          'name': 'Fixed Asset Management'
        },
        {
          'id': 10,
          'name': 'General Human Resources Management'
        },
        {
          'id': 11,
          'name': 'Information and Communications Technology'
        },
        {
          'id': 12,
          'name': 'Inventory Management'
        },
        {
          'id': 13,
          'name': 'Learning and Development'
        },
        {
          'id': 14,
          'name': 'Payroll Management and Compliance'
        },
        {
          'id': 15,
          'name': 'Performance Management'
        },
        {
          'id': 16,
          'name': 'Procurement (Purchasing)'
        },
        {
          'id': 17,
          'name': 'Production'
        },
        {
          'id': 18,
          'name': 'Retail Operations'
        },
        {
          'id': 19,
          'name': 'Sales'
        },
        {
          'id': 20,
          'name': 'Tax Planning and Compliance'
        },
        {
          'id': 21,
          'name': 'Others'
        }
      ],
      profile: {
        // org: {
        //   name: 'Judith Stiches',
        //   address: '22 Abagana road, Obukpa',
        //   specialization: '1',
        //   industry: '2',
        //   business_nature: '',
        // },
        org: {
          name: '',
          address: '',
          specialization: '',
          industry: '',
          business_nature: '',
        },
        // contact: {
        //   fname: 'Judith',
        //   gender: 'Female',
        //   partner_type: 'var',
        //   lname: 'Okwu',
        //   phone_no: '09037478933',
        //   email: 'judith@gmail.com',
        //   address: 'Nsukka Road'
        // }
        contact: {
          is_org: '',
          fname: '',
          gender: '',
          partner_type: '',
          is_an_affiliate: false,
          lname: '',
          phone_no: '',
          email: '',
        }
      },
    };
  },
  methods: {
    ValidateEmail(mail) {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true;
      }
      alert('You have entered an invalid email address!');
      this.submitting = false;
      return false;
    },
    validateData() {
      if (
        this.validateField(this.profile.contact.is_org, 2) &&
        this.validateField(this.profile.contact.fname, 3) &&
        this.validateField(this.profile.contact.lname, 3) &&
        this.validateField(this.profile.contact.email, 3) &&
        this.validateField(this.profile.contact.gender, 3) &&
        this.validateField(this.profile.contact.phone_no, 3) &&
        this.validateField(this.profile.org.address, 3) &&
        // this.validateField(this.profile.org.name, 3) &&
        this.validateField(this.profile.org.specialization, 1) &&
        this.validateField(this.profile.org.industry, 1) &&
        this.validateField(this.profile.org.business_nature, 1)
      ) {
        return true;
      } else {
        alert('all fields are required');
        this.submitting = false;
        return false;
      }
    },
    validateField(field, length) {
      console.log(field);
      if (field.toString().length >= length) {
        return true;
      }
    },
    apply() {

      if (this.profile.org.specialization === null || this.profile.org.specialization === '') {
        this.submitting = false;
        alert('Industry Sub-sector is  required');
        return false;
      } else if (this.profile.org.business_nature === null || this.profile.org.business_nature === '') {
        this.submitting = false;
        alert('Core Area of Specialization is  required');
        return false;
      } else if (this.profile.org.industry === null || this.profile.org.industry === '') {
        this.submitting = false;
        alert('Industry Sector is  required');
        return false;
      } else if (this.profile.org.specialization === null || this.profile.org.specialization === '') {
        this.submitting = false;
        alert('Industry Sub-sector is  required');
        return false;
      } else if (!(this.profile.contact.partner_type === 'var' || this.profile.contact.partner_type === 'reseller' || this.profile.contact.is_an_affiliate)) {
        alert('you need to select partner type');
        this.submitting = false;
        return false;
      } else if (this.checked) {
        if (this.ValidateEmail(this.profile.contact.email) && this.validateData()) {
          this.submitting = true;
          this.$_apply(this.profile)
            .then(response => {
              this.$router.push({ name: 'ApplyCompleted' });
            })
            .catch((error => {
              console.log(error.response);
              alert(error.response.data.message);
              this.submitting = false;

            }).bind(this));
        }
      } else {
        alert('kindly accept the terms and conditions');
        this.submitting = false;

      }

    },
    loadSpecializations() {
      console.log(this.profile.org.industry);
      let filteredIndustries = this.industries.filter((data) => {
        return data.id === this.profile.org.industry;
      });
      this.specializations = filteredIndustries[0].subIndustries;
      console.log(this.specializations);
    },
    togglePartnerType(value) {
      // if (value === 'var' && this.varChecked) {
      //   this.varChecked = false;
      // } else if (value === 'reseller' && this.resellerChecked) {
      //   this.resellerChecked = false;
      // } else
      if (value === 'var' && this.resellerChecked) {
        this.resellerChecked = false;
      } else if (value === 'reseller' && this.varChecked) {
        this.varChecked = false;
      }
      if (!this.resellerChecked && !this.varChecked) {
        this.profile.contact.partner_type = '';
      }else {
        this.profile.contact.partner_type = value;
      }

    }
  },
  mounted() {
    this.industries = this.$_getIndustries().industries;

  }
};
</script>
