<template>
  <div class="mt-10">
    <div class="d-flex justify-content-center">
      <div class="col-md-4 col-sm-12">
        <div class="row">
          <div class="col">
            <img :src="require('../assets/cloudenlylargelogo.png')" class="w-16 p-0 m-0" alt="">
          </div>
          <div class="col-10">
            <h5 class="font-bold">CLOUDENLY PARTNERS </h5>
          </div>
        </div>
        <hr>

        <div class="form-group">
          <div class="alert alert-danger" role="alert" v-if="showAlert">
            invalid username and password
          </div>
          <label for="email">Email address</label>
          <input placeholder="Email" v-model="account.email" type="email" class="form-control" id="email"
                 aria-describedby="emailHelp">

        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input @keydown.enter="login" placeholder="Password" v-model="account.password" type="password" class="form-control" id="password">
        </div>
        <button type="submit"  @click="login" class="btn btn-primary">Login</button>

      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      showAlert: false,
      account: {
        email: '',
        password: ''
      }
    };
  },
  methods: {
    async login() {
      this.showLoader();

      // let ip=(await this.$_getIp()).response.data.ip
      // console.log(ip)
      this.$_login(this.account)
        .then((response) => {

            // response.data.info = "auth";
            // localStorage.setItem("userData", JSON.stringify(response.data))
            let userData = response.data;

            localStorage.setItem('token', JSON.stringify(userData.token));
            localStorage.setItem('accountUser', JSON.stringify(userData.accountUser));

            this.hideLoader();
            if (userData.accountUser.auth_role === 'mgt') {
               this.$router.replace({ name: 'Admin' });
            } else {
              if (userData.accountUser.doc_signed) {
                 this.$router.replace({ name: 'SignComplete' });
              } else {
                 this.$router.replace({ name: 'PreSignForm' });
              }
            }

          }
        )
        .catch(() => {
          this.showAlert = true;
          setTimeout(function (){
            this.showAlert = false;
          }.bind(this),3000)
          this.hideLoader();
        });
    },
  }
};
</script>
