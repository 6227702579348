<template>
  <div class="mt-10">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div class="row">
            <div class="col-12 text-center">
              <p>
                <span v-if="$accountUser.is_an_affiliate" class="text-xl"><span>✅</span> <span class=" mr-3"><strong>Affiliate</strong></span></span>
                <span v-if="$accountUser.partner_type==='reseller'" class="text-xl"><span>✅</span> <span class=" mr-3"><strong>Reseller</strong></span></span>
                <span v-if="$accountUser.partner_type==='var'" class="text-xl"><span>✅</span> <span class=" mr-3"><strong>Value Added Reseller</strong></span></span>
              </p>
              <p>Dear <strong>{{ $accountUser.fname }} {{ $accountUser.lname }},</strong></p>
              <p>Thanks once again for applying to be a Cloudenly Partner. </p>
              <p>We are working on this portal to give you a better experience. </p>
              <p>Please check  back later.</p>
              <div class="row"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SignCompleted',
  components: {},
  data() {
    return {

    }
  },
  methods: {

  },
  async mounted() {

  }
};
</script>
